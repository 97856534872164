import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Banner from "../components/Banner/banner2"
import Slider from "react-slick"
import "../helpers/styles/pages/educacao.scss"

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  )
}

export const query = graphql`
  query Educacao($locale: String!) {
    childEducacaoJson: strapiEducacao(locale: { eq: $locale }) {
      Banner {
        bannerTitle
        background {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, maxHeight: 294) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      VideoSlider {
        videoURL
      }
      introText
      jobsList {
        data {
          jobsList
        }
      }
      jobsTitle
      knowMoreMsc
      locale
      mscTitle
      mscURL
      objectivesFirstParagraph {
        data {
          objectivesFirstParagraph
        }
      }
      objectivesList {
        data {
          objectivesList
        }
      }
      objectivesSecondParagraph {
        data {
          objectivesSecondParagraph
        }
      }
      professionalSkillsList {
        data {
          professionalSkillsList
        }
      }
      professionalSkillsParagraph {
        data {
          professionalSkillsParagraph
        }
      }
      professionalSkillsTitle
    }
  }
`

export default ({ pageContext: { locale, currentPath }, data }) => {
  const content = data.childEducacaoJson

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    className: "video-slider",
  }

  return (
    <Layout locale={locale} currentPath={currentPath}>
      <Banner
        banner={content.Banner.background.localFile}
        text={content.Banner.bannerTitle}
        layer={false}
        opacityValue={1}
        //logo={obcrei}
      />
      <div className="container" style={{ marginBottom: 100 }}>
        <div className="col-sm-10 col-xs-12 text-left ">
          <h2 style={{ marginTop: 100, marginBottom: 45 }}>
            {content.mscTitle}
          </h2>
        </div>
        <div className="col-sm-10 col-xs-12 text-left color-primary">
          {content.introText}
        </div>
        <div
          style={{
            marginTop: 25,
            marginBottom: 25,
            paddingLeft: 76,
            paddingRight: 61,
          }}>
          <Slider {...settings}>
            {content?.VideoSlider.map(video => (
              <iframe
                key={video.videoURL}
                width="800"
                height="450"
                src={video.videoURL}
                title={content.mscTitle}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            ))}
          </Slider>
        </div>
        <div
          className="col-sm-10 col-xs-12 text-left color-primary"
          style={{ paddingTop: 10 }}
          dangerouslySetInnerHTML={{
            __html:
              content.objectivesFirstParagraph.data.objectivesFirstParagraph,
          }}></div>
        <div
          className="col-sm-10 col-xs-12 text-default text-left color-primary"
          dangerouslySetInnerHTML={{
            __html:
              content.objectivesSecondParagraph.data.objectivesSecondParagraph,
          }}></div>
        <div
          style={{ marginTop: 30, lineHeight: "32px", paddingLeft: 50 }}
          dangerouslySetInnerHTML={{
            __html: content.objectivesList.data.objectivesList,
          }}></div>
        <a
          href={content.mscURL}
          target="_blank"
          rel="noopener noreferrer"
          className="link--red bold">
          {content.knowMoreMsc}
        </a>

        <div className="col-sm-10 col-xs-12 text-left ">
          <h2 style={{ marginTop: 100, marginBottom: 45 }}>
            {content.professionalSkillsTitle}
          </h2>
        </div>
        <div
          className=" col-sm-10 col-xs-12 text-default color-primary"
          dangerouslySetInnerHTML={{
            __html:
              content.professionalSkillsParagraph.data
                .professionalSkillsParagraph,
          }}></div>
        <div
          style={{ marginTop: 30, lineHeight: "32px", paddingLeft: 50 }}
          dangerouslySetInnerHTML={{
            __html: content.professionalSkillsList.data.professionalSkillsList,
          }}></div>

        <div className="col-sm-10 col-xs-12 text-left ">
          <h2 style={{ marginTop: 100, marginBottom: 45 }}>
            {content.jobsTitle}
          </h2>
        </div>
        <div
          style={{ marginTop: 30, lineHeight: "32px", paddingLeft: 50 }}
          dangerouslySetInnerHTML={{
            __html: content.jobsList.data.jobsList,
          }}></div>
      </div>
    </Layout>
  )
}
// export const educacao = graphql`
//   query($locale: String) {
//     file(
//       name: { eq: $locale }
//       relativeDirectory: { eq: "textContent/educacao" }
//     ) {
//       childEducacaoJson {
//         bannerText
//         bannerImage {
//           childImageSharp {
//             fluid(maxWidth: 1200, maxHeight: 294) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//         mscTitle
//         introText
//         objectivesFirstParagraph
//         objectivesSecondParagraph
//         objectivesList
//         knowMoreMsc
//         professionalSkillsTitle
//         professionalSkillsParagraph
//         professionalSkillsList
//         jobsTitle
//         jobsList
//         videoURL
//         mscURL
//       }
//     }
//   }
// `
